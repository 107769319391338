/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import EditIcon from 'Component/EditIcon';

// import MyAccountAddressTable from 'Component/MyAccountAddressTable/MyAccountAddressTable.container';
import MyAccountNewReturnAddressPopup from '../MyAccountNewReturnAddressPopup';

import './MyAccountNewReturnAddressTable.style';

/** @namespace Rma/Component/MyAccountNewReturnAddressTable/Component/MyAccountNewReturnAddressTableComponent */
export class MyAccountNewReturnAddressTableComponent extends PureComponent {
     static propTypes = {
         isEditShow: PropTypes.bool,
         address: PropTypes.objectOf(),
         updateReturnAddress: PropTypes.func.isRequired,
         onViewAccountReturnAddress: PropTypes.func.isRequired
     };

     static defaultProps = {
         isEditShow: false,
         address: {}
     };

     renderRmaAddressEditPopup() {
         const { address, updateReturnAddress } = this.props;
         return (
             <MyAccountNewReturnAddressPopup address={ address } updateReturnAddress={ updateReturnAddress } />
         );
     }

     renderEditButton() {
         const { address, onViewAccountReturnAddress, isEditShow } = this.props;

         if (address && Object.keys(address).length > 0 && isEditShow) {
             return <span onClick={ onViewAccountReturnAddress }><EditIcon /></span>;
         }

         return null;
     }

     render() {
         const { address } = this.props;

         return (
             <div
               block="MyAccountDashboard"
               elem="DefaultAddress"
             >
                 { this.renderEditButton() }
                     <div block="MyAccountReturnAddress">
                         <h4>{ __('Address') }</h4>
                     { address && Object.keys(address).length > 0 && (
                         <ul>
                             <li>{ `${ address.firstname } ${ address.lastname}` }</li>
                             <li>{ address.street }</li>
                             <li>{ address.city }</li>
                             <li>{ `${ __('Pin Code: ') } ${ address.postcode }` }</li>
                             <li>{ `${ __('Mobile:') } ${ address.telephone}` }</li>
                         </ul>
                     ) }
                     </div>
                 { this.renderRmaAddressEditPopup() }
             </div>
         );
     }
}

export default MyAccountNewReturnAddressTableComponent;
