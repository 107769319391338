import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/ProductList/Query/ProductListQuery */
export class ProductListQuery extends SourceProductListQuery {
    _getProductInterfaceFields(isVariant, _isForLinkedProducts = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        // Basic fields returned always
        const fields = [
            'id',
            'sku',
            'discount',
            'offer_label',
            'name',
            'type_id',
            'stock_status',
            this._productLabelField(),
            this._getStockItemField()
        ];

        // Additional A+ fields
        if (!isPlp) {
            fields.push(
                'a_content1_title',
                'a_content1_video_url',
                'a_content1_description',
                this._productAplusImageField('a_content1_image'),
                'a_content2_title',
                'a_content2_video_url',
                'a_content2_description',
                this._productAplusImageField('a_content2_image'),
                this._productAplusImageField('a_content1_image_mobile'),
                this._productAplusImageField('a_content2_image_mobile')
            );
        }

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant)) {
            fields.push(
                this._getPriceRangeField(),
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributesimport { Field } from 'Util/Query';
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment()
                );
            }
        }

        // prevent linked products from looping
        // if (isForLinkedProducts) {
        //     fields.push(this._getProductLinksField());
        // }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'meta_title',
                'meta_keyword',
                'canonical_url',
                'meta_description',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                // this._getProductLinksField(),
                this._getCustomizableProductFragment(),
                this._sizeChartField(),
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment()
                );
            }
        }

        return fields;
    }

    _productLabelField() {
        const productLabel = new Field('productlabel')
            .addArgument('fields', '[String]', 'product_label')
            .setAlias('product_label');

        return productLabel;
    }

    _sizeChartField() {
        const sizeChart = new Field('sizechart')
            .addArgument('fields', '[String]', 'size_chart')
            .setAlias('size_chart');

        return sizeChart;
    }

    _productAplusImageField(key) {
        return new Field(key)
            .addFieldList(['url', 'label']);
    }
}

export default new ProductListQuery();
