/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import MyAccountOrderTabs from 'Component/MyAccountOrderTabs';
import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import { MY_ORDERS, orderType } from 'Type/Account';
import { renderFormattedDate } from 'Util/DateTime';
import { formatPrice } from 'Util/Price';

import {
    ORDER_INVOICES,
    ORDER_ITEMS,
    ORDER_SHIPMENTS
} from './MyAccountViewOrder.config';

import './MyAccountViewOrder.style';

/** @namespace Scandipwa/Component/MyAccountViewOrder/Component/MyAccountViewOrderComponent */
export class MyAccountViewOrderComponent extends PureComponent {
      static propTypes = {
          order: orderType.isRequired,
          currency_code: PropTypes.string.isRequired,
          isLoading: PropTypes.bool.isRequired,
          activeTab: PropTypes.string.isRequired,
          handleChangeActiveTab: PropTypes.func.isRequired
      };

        tabMap = {
            [ORDER_ITEMS]: {
                tabName: ORDER_ITEMS,
                title: __('Items Ordered'),
                shouldTabRender: () => {
                    const { order } = this.props;

                    return order;
                },
                render: () => this.renderOrderItems()
            },
            [ORDER_INVOICES]: {
                tabName: ORDER_INVOICES,
                title: __('Invoices'),
                shouldTabRender: () => {
                    const { order } = this.props;

                    return order;
                },
                render: () => this.renderOrderItems()
            },
            [ORDER_SHIPMENTS]: {
                tabName: ORDER_SHIPMENTS,
                title: __('Order Shipments'),
                shouldTabRender: () => {
                    const { order } = this.props;

                    return order;
                },
                render: () => this.renderOrderItems()
            }
        };

        componentDidMount() {
            // When the page loads, scroll to the top of the page
            window.scrollTo(0, 0);
        }

        renderActiveTab() {
            const { activeTab } = this.props;
            return this.tabMap[activeTab].render();
        }

        shouldTabsRender() {
            return Object.values(this.tabMap).filter(({ shouldTabRender }) => shouldTabRender());
        }

        renderOrderItems() {
            return (
                // eslint-disable-next-line react/destructuring-assignment
                <h1>{ this.props.activeTab }</h1>
            );
        }

        renderActions() {
            const {
                handleChangeActiveTab,
                activeTab,
                isLoading
            } = this.props;

            if (isLoading) {
                return null;
            }

            return (
                <div block="MyAccountOrder" elem="Actions">
                    <MyAccountOrderTabs
                      tabs={ this.shouldTabsRender() }
                      handleChangeActiveTab={ handleChangeActiveTab }
                      activeTab={ activeTab }
                    />
                </div>
            );
        }

        renderBaseInfo() {
            const { order: { base_order_info } } = this.props;
            const { status_label, created_at, increment_id } = base_order_info[0];
            return (
             <div>
                     <div block="MyAccountViewOrder" elem="heading">
                     <h2>
                     <span>
                     { __('Order #') }
                         { increment_id }

                     </span>
                     </h2>
                     </div>
                 <dl>
                     <dt>{ status_label }</dt>
                     <dt>
                         { __('Order Date: ') }
                         { ' ' }
                         { renderFormattedDate(created_at, 'MMMM d, yyyy') }
                     </dt>
                 </dl>

             </div>

            );
        }

        renderPayment() {
            const { order: { payment_info } } = this.props;
            const { method_type, additional_information: { method_title } = {} } = payment_info || {};
            const payment_method_type = method_type ? ` ${ method_type }` : null;
            return (
               <div>
                   <h4>{ __('Payment Information') }</h4>
                   <dl>
                       <dt>{ payment_method_type || method_title }</dt>
                   </dl>
               </div>
            );
        }

        renderShippingAddressTable() {
            const { order: { shipping_info: { shipping_address } } } = this.props;

            return (
               <MyAccountAddressTable
                 title={ __('Shipping address') }
                 address={ shipping_address }
                 mix={ { block: 'MyAccountViewOrder', elem: 'Address' } }
               />
            );
        }

        renderShipping() {
            const { order: { shipping_info } } = this.props;

            const {
                // eslint-disable-next-line no-unused-vars
                shipping_address
            } = shipping_info || {};

            if (!shipping_address) {
                return null;
            }

            return (
               <div block="MyAccountViewOrder" elem="ShippingWrapper">
                   <h3>{ __('ORDER INFORMATION') }</h3>
                   { this.renderShippingAddressTable() }
               </div>
            );
        }

        renderItemStatus(product) {
            const { order: { fynd_order_details } } = this.props;
            const itemStatus = fynd_order_details.filter((detail) => (detail.item_status && product.order_item_id === detail.cms_order_item_id));
            return (
                itemStatus.map((status) => {
                    if (status.item_rma_status_label) {
                        return <div>{ status.item_rma_status_label }</div>;
                    }

                    if (status.item_status_label) {
                        return <div>{ status.item_status_label }</div>;
                    }

                    return null;
                })
            );
        }

        renderItemInvoices(product) {
            const { order: { fynd_order_details } } = this.props;
            const invoices = fynd_order_details.filter((detail) => (detail.invoice_pdf && product.order_item_id === detail.cms_order_item_id));
            return (
                invoices.map((invoice) => (
                     <a block="Font" elem="Regular" target="_blank" href={ invoice.invoice_pdf } rel="noreferrer">
                         { invoice.bag_id }
                     </a>
                ))
            );
        }

        renderItemShipments(product) {
            const { order: { fynd_order_details } } = this.props;
            const Shippments = fynd_order_details.filter((shippment) => (shippment.invoice_pdf && product.order_item_id === shippment.cms_order_item_id));
            return (
                Shippments.map((shipment) => (
                     <a block="Font" elem="Regular" target="_blank" href={ shipment.track_url } rel="noreferrer">
                         { __('Track') }
                     </a>
                ))
            );
        }

        renderSelectedOptionAttribute(selected_options) {
            return selected_options.map((option) => (
             <div block={ option.label } key={ option.label }>
                 <span>
                 { `${ option.label }: ${ option.value }` }
                 </span>
             </div>
            ));
        }

        renderItems() {
            const { order: { order_products = [] } } = this.props;
            const { order: { base_order_info } } = this.props;
            const { currency_code } = base_order_info[0];

            return order_products.map((product, i) => {
                const {
                    name,
                    thumbnail,
                    id,
                    product_sku,
                    qty,
                    row_total,
                    selected_options
                } = product;

                const { url } = thumbnail || {};

                return (
                   <tr
                     key={ id || i.toString() }
                     block="MyAccountViewOrder"
                     elem="Row"
                   >
                       <td>
                           { url && (
                               <Image
                                 src={ url }
                                 alt={ name }
                                 mix={ { block: 'MyAccountViewOrder', elem: 'Thumbnail' } }
                               />
                           ) }
                       </td>
                       <td>
                           { name }
                           { this.renderSelectedOptionAttribute(selected_options) }
                       </td>
                       <td>{ product_sku }</td>
                       <td>{ formatPrice(row_total, currency_code) }</td>
                       <td>{ qty }</td>
                       <td>{ this.renderItemStatus(product) }</td>
                       <td>{ formatPrice(row_total, currency_code) }</td>
                       <td>{ this.renderItemInvoices(product) }</td>
                       <td>{ this.renderItemShipments(product) }</td>
                   </tr>
                );
            });
        }

        renderItemsHeading() {
            return (
               <tr>
                   <th colSpan={ 2 }>{ __('Product Name') }</th>
                   <th>{ __('Sku') }</th>
                   <th>{ __('Price') }</th>
                   <th>{ __('Qty') }</th>
                   <th>{ __('Status') }</th>
                   <th>{ __('Subtotal') }</th>
                   <th>{ __('Invoices') }</th>
                   <th>{ __('Tracking') }</th>
               </tr>
            );
        }

        renderProducts() {
            return (
               <div block="MyAccountViewOrder" elem="ProductsWrapper">
                   <h4>{ __('Items Ordered') }</h4>
                   <div block="Table" elem="Wrapper">
                   <table
                     block="MyAccountViewOrder"
                     elem="Products"
                   >
                       <thead>
                           { this.renderItemsHeading() }
                       </thead>
                       <tbody>
                           { this.renderItems() }
                       </tbody>
                   </table>
                   </div>
               </div>
            );
        }

        renderTotals() {
            const { order: { base_order_info, shipping_info: { shipping_amount } } } = this.props;
            const {
                grand_total, sub_total, currency_code, credit_amount, discount_amount
            } = base_order_info[0];

            return (
             <div block="MyAccountViewOrder" elem="OrderWrapper">
                 <dl>
                     <dt>{ __('Subtotal: ') }</dt>
                     <dd>
                         { formatPrice(sub_total, currency_code) }
                     </dd>
                     <dt>{ __('Shipping Amount: ') }</dt>
                     <dd>
                         { formatPrice(shipping_amount, currency_code) }
                     </dd>
                     { credit_amount > 0 && (
                         <>
                             <dt block="discount">{ __('Store Credit: ') }</dt>
                             <dd block="discount">
                                 { formatPrice(-credit_amount, currency_code) }
                             </dd>
                         </>
                     ) }
                     { discount_amount !== 0 && (
                         <>
                             <dt block="discount">{ __('Coupon Discount: ') }</dt>
                             <dd block="discount">
                             { formatPrice(discount_amount, currency_code) }
                             </dd>
                         </>
                     ) }
                     <dt block="bold">{ __('Grand total: ') }</dt>
                     <dd block="bold">
                         { formatPrice(grand_total, currency_code) }
                     </dd>
                 </dl>
             </div>
            );
        }

        renderBackButton() {
            return (
                 <div block="MyAccountViewOrder" elem="BackOrderHistory">
                     <Link to={ `${MY_ACCOUNT_URL}/${MY_ORDERS}` }>{ __('BACK TO ORDER history') }</Link>
                 </div>
            );
        }

        renderContent() {
            const { order: { order_products } } = this.props;

            if (!order_products) {
                return null;
            }

            return (
                <>
                  { this.renderBaseInfo() }
                  { this.renderProducts() }
                  { this.renderTotals() }
                  { this.renderBackButton() }
                  { this.renderShipping() }
                  { this.renderPayment() }
                </>
            );
        }

        render() {
            const { isLoading } = this.props;
            return (
                  <div block="MyAccountViewOrder" elem="Content">
                      <Loader isLoading={ isLoading } />
                      { this.renderContent() }
                      { /* { this.renderActions() }
                      { this.renderActiveTab() } */ }
                  </div>
            );
        }
}

export default MyAccountViewOrderComponent;
