/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Field from 'Component/Field';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';

import './MyAccountNewReturnItemSelect.style';

/** @namespace Rma/Component/MyAccountNewReturnItemSelect/Component/MyAccountNewReturnItemSelectComponent */
export class MyAccountNewReturnItemSelectComponent extends PureComponent {
     static propTypes = {
         items: PropTypes.arrayOf().isRequired,
         onItemChange: PropTypes.func.isRequired,
         reasonData: PropTypes.objectOf().isRequired,
         // eslint-disable-next-line react/boolean-prop-naming
         hasError: PropTypes.bool.isRequired
     };

     state = {
         selectedItems: {}
     };

     handleItemSelect = (isChecked, id) => {
         const { onItemChange, items: orderItems } = this.props;
         const { selectedItems: items } = this.state;
         // eslint-disable-next-line fp/no-let
         let selectedItems = { ...items };

         if (isChecked) {
             selectedItems = omit(selectedItems, id);
         } else {
             const itemById = Object.values(orderItems).find(
                 (obj) => parseInt(obj.order_item_id, 10) === parseInt(id, 10)
             );

             selectedItems[id] = {
                 order_item_id: id,
                 product_sku: itemById.product_sku,
                 qty_requested: 1,
                 reason_id: '',
                 condition_id: '',
                 resolution_id: ''
             };
         }

         this.setState({ selectedItems });
         onItemChange(selectedItems);
     };

     handleReasonBlockSelect(blockId, key, id) {
         const { onItemChange } = this.props;
         const {
             selectedItems: items,
             selectedItems: { [id]: selectedItem }
         } = this.state;

         const selectedItems = {
             ...items,
             [id]: {
                 ...selectedItem,
                 [key]: blockId
             }
         };

         this.setState({ selectedItems });
         onItemChange(selectedItems);
     }

     handleQtyChange(qty, id) {
         const { onItemChange } = this.props;
         const {
             selectedItems: items,
             selectedItems: { [id]: selectedItem }
         } = this.state;

         const selectedItems = {
             ...items,
             [id]: {
                 ...selectedItem,
                 qty_requested: qty
             }
         };

         this.setState({ selectedItems });
         onItemChange(selectedItems);
     }

     handleBankTransferFieldChange(value, key, id) {
         const { onItemChange } = this.props;
         const {
             selectedItems: items,
             selectedItems: { [id]: selectedItem }
         } = this.state;

         const selectedItems = {
             ...items,
             [id]: {
                 ...selectedItem,
                 [key]: value
             }
         };

         this.setState({ selectedItems });
         onItemChange(selectedItems);
     }

     renderImage({ name, thumbnail: { url: thumbnailUrl }, small_image: { url: small_imageUrl } }) {
         return (
             <>
                 <Image
                   src={ thumbnailUrl }
                   mix={ {
                       block: 'ReturnItem',
                       elem: 'Picture'
                   } }
                   ratio="custom"
                   alt={ `Product ${ name } thumbnail.` }
                 />
                 <img
                   style={ { display: 'none' } }
                   alt={ name }
                   src={ small_imageUrl }
                   itemProp="image"
                 />
             </>
         );
     }

     renderSelectedOptionAttribute(selected_options) {
         return selected_options.map((option) => (
            <div block={ option.label } key={ option.label }>
                <span>
                { `${ option.label }: ${ option.value }` }
                </span>
            </div>
         ));
     }

     renderItemDetails(item) {
         const { name, selected_options } = item;

         return (
             <div
               block="ReturnItem"
               elem="Details"
             >
                 <p
                   block="ReturnItem"
                   elem="Heading"
                 >
                     { name }
                 </p>
                 <div
                   block="ReturnItem"
                   elem="Attributes"
                 >
                     { this.renderSelectedOptionAttribute(selected_options) }
                 </div>
             </div>
         );
     }

     /**
      * Render attributes
      * @param attributes
      * @returns {*}
      */
     renderAttributes(attributes) {
         const configurable_options = ['color', 'size'];

         const configurableAttributes = Object.values(attributes)
             .filter((attr) => configurable_options.includes(attr.attribute_code));

         return configurableAttributes.map((attribute) => attribute.attribute_value && this.renderAttribute(attribute));
     }

     /**
      * Render attribute row
      * @param attribute_value
      * @param label
      * @param attribute_options
      * @returns {null|*}
      */
     renderAttribute({ attribute_value, attribute_label: label, attribute_options }) {
         if (!attribute_value) {
             return null;
         }

         return (
             <div block={ label } key={ label }>
                 <span>
                     { `${ label }: ${ attribute_options.length ? attribute_options[0].label : attribute_value }` }
                 </span>
             </div>
         );
     }

     renderReasonBlockSelect(title, options, key, id) {
         const { hasError } = this.props;
         const { selectedItems: { [id]: item } } = this.state;
         const value = item[key] || '';
         const errorMessage = (hasError && !value) ? __('%s is required', title) : '';

         return (
             <div
               block="MyAccountNewReturnItemSelect"
               elem="SelectWrapper"
             >
                 <span
                   block="MyAccountNewReturnItemSelect"
                   elem="SelectTitle"
                 >
                     { title }
                 </span>
                 <Field
                   id={ title }
                   name={ title }
                   type="select"
                   placeholder={ __('Choose %s', title.toLowerCase()) }
                   mix={ {
                       block: 'MyAccountNewReturnItemSelect',
                       elem: 'SelectInput'
                   } }
                   selectOptions={ options }
                   value={ value }
                   /* eslint-disable-next-line react/jsx-no-bind */
                   onChange={ (blockId) => this.handleReasonBlockSelect(blockId, key, id) }
                 />
                 { errorMessage && <p className="Field-Message">{ errorMessage }</p> }
             </div>
         );
     }

     renderReasonBlockQty(id, orderedQty) {
         const { hasError } = this.props;
         const { selectedItems: { [id]: { qty_requested } } } = this.state;
         const errorMessage = (hasError && !qty_requested) ? __('Choose qty!') : '';

         return (
             <div
               key={ id }
               block="MyAccountNewReturnItemSelect"
               elem="QtyBlockWrapper"
               mods={ { isDisabled: !orderedQty < 0 } }
             >
                     <span>
                         { __('Return Qty:') }
                         &nbsp;
                     </span>
                     <Field
                       id="item_qty"
                       name="item_qty"
                       type="number"
                       isControlled
                       validation={ ['notEmpty'] }
                       min={ 1 }
                       max={ 1 }
                       value={ qty_requested }
                     /* eslint-disable-next-line react/jsx-no-bind */
                       onChange={ (qty) => this.handleQtyChange(qty, id) }
                     />
                     <span>{ ` / ${ orderedQty }` }</span>
                     { errorMessage && <p className="Field-Message">{ errorMessage }</p> }
             </div>
         );
     }

     renderReasonBlockRules() {
         return (
             <span
               block="MyAccountNewReturnItemSelect"
               elem="ReasonBlockRuleTitle"
             >
                 { __('The return for this product can’t be processed.') }
             </span>
         );
     }

     renderReasonBlockInputs(id, qty_available_to_return) {
         const { reasonData: { reasons, conditions, resolutions } } = this.props;

         return (
             <div key={ id }>
                 { this.renderReasonBlockQty(id, qty_available_to_return) }
                 { this.renderReasonBlockSelect(__('Return Reason'), reasons, 'reason_id', id) }
                 { this.renderReasonBlockSelect(__('Item Condition'), conditions, 'condition_id', id) }
                 { this.renderReasonBlockSelect(__('Return Resolution'), resolutions, 'resolution_id', id) }
                 { this.isDisplayBankTransferBlock(id) && this.renderBankTransferBlock(id) }
             </div>
         );
     }

     isDisplayBankTransferBlock(id) {
         const { selectedItems: { [id]: item } } = this.state;
         const { reasonData: { resolutions } } = this.props;

         const bankTransferId = resolutions.find((resolution) => resolution.label === 'Bank Transfer');

         if (item.resolution_id && bankTransferId && item.resolution_id === bankTransferId.value) {
             return true;
         }

         return false;
     }

     renderBankTransferBlock(id) {
         return (
             <div
               block="MyAccountNewReturnItemSelect"
               elem="BankTransferWrapper"
             >
                 { this.renderBankTransferBlockField(__('Customer Name'), 'customer_name', id) }
                 { this.renderBankTransferBlockField(__('Bank Name'), 'bank_name', id) }
                 { this.renderBankTransferBlockField(__('Account Number'), 'account_number', id) }
                 { this.renderBankTransferBlockField(__('IFSC Code'), 'ifsc_code', id) }
             </div>
         );
     }

     renderBankTransferBlockField(title, key, id) {
         const { hasError } = this.props;
         const { selectedItems: { [id]: item } } = this.state;
         const value = item[key] || '';
         const errorMessage = (hasError && !value) ? __('%s is required', title) : '';

         return (
             <div
               block="MyAccountNewReturnItemSelect"
               elem="TextInputWrapper"
             >
                 <span
                   block="MyAccountNewReturnItemSelect"
                   elem="SelectTitle"
                 >
                     { title }
                 </span>
                 <Field
                   id={ key }
                   name={ key }
                   type="text"
                   placeholder={ __('Enter %s', title) }
                   mix={ {
                       block: 'MyAccountNewReturnItemSelect',
                       elem: 'FieldInput'
                   } }
                   value={ value }
                   onChange={ (value) => this.handleBankTransferFieldChange(value, key, id) }
                 />
                 { errorMessage && <p className="Field-Message">{ errorMessage }</p> }
             </div>
         );
     }

     renderReasonBlock(item, id, isChecked, isDisabled) {
         const { id: itemId, qty_available_to_return } = item;

         if (!isChecked && !isDisabled) {
             return null;
         }

         return (
             <div
               key={ itemId }
               block="MyAccountNewReturnItemSelect"
               elem="ReasonBlockWrapper"
               mods={ { isRulesBlock: isDisabled } }
             >
                 { isDisabled
                     ? this.renderReasonBlockRules()
                     : this.renderReasonBlockInputs(id, qty_available_to_return, item) }
             </div>
         );
     }

     renderItemField(item, id, isChecked, isDisabled) {
         return (
             <div
               key={ id }
               block="MyAccountNewReturnItemSelect"
               elem="ItemInnerWrapper"
             >
                 <figure block="ReturnItem" elem="Wrapper">
                     { this.renderImage(item) }
                     { this.renderItemDetails(item) }
                 </figure>
                 { this.renderItemCheckbox(id, isChecked, isDisabled) }
             </div>
         );
     }

     renderItemCheckbox(id, isChecked, isDisabled) {
         if (isDisabled) {
             return null;
         }

         return (
             <Field
               id={ `${ id }` }
               name={ `${ id }` }
               value={ id }
               type="checkbox"
               mix={ {
                   block: 'MyAccountNewReturnItemSelect',
                   elem: 'ItemField',
                   mods: { isDisabled }
               } }
               disabled={ isDisabled }
               checked={ isChecked }
                 /* eslint-disable-next-line react/jsx-no-bind */
               onChange={ () => this.handleItemSelect(isChecked, id, isDisabled) }
             />
         );
     }

     renderItem = (item, index) => {
         const { selectedItems } = this.state;
         const { order_item_id, qty_available_to_return } = item;

         const id = parseInt(order_item_id, 10);
         const isChecked = !!selectedItems[id];
         const isDisabled = qty_available_to_return === 0;

         return (
             <div
               block="MyAccountNewReturnItemSelect"
               elem="ItemWrapper"
               key={ index }
             >
                 { this.renderItemField(item, id, isChecked, isDisabled) }
                 { this.renderReasonBlock(item, id, isChecked, isDisabled) }
             </div>
         );
     };

     renderItems() {
         const { items } = this.props;

         if (!items.length) {
             return (
                 <>
                     <TextPlaceholder
                       mix={ {
                           block: 'MyAccountNewReturnItemSelect',
                           elem: 'ItemImagePlaceholder'
                       } }
                     />
                     <TextPlaceholder />
                 </>
             );
         }

         return (
             <div>
                 { items.map((item, index) => this.renderItem(item, index)) }
             </div>
         );
     }

     renderTitle() {
         return (
             <h4
               block="MyAccountNewReturnItemSelect"
               elem="Title"
             >
                 { __('Choose Products') }
             </h4>
         );
     }

     render() {
         return (
             <div block="MyAccountNewReturnItemSelect">
                 { this.renderTitle() }
                 { this.renderItems() }
             </div>
         );
     }
}

export default MyAccountNewReturnItemSelectComponent;
