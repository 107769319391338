import { Field } from 'Util/Query';

const _getStoreConfigFields = (args, callback) => [
    ...callback(...args),
    new Field('zendesk_key')
];

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
