export const LICENSE_CODE = 'in~58adcc85';

export const CATEGORY_VIEW = 'category_view';
export const SUBCATEGORY_VIEW = 'subcategory_view';
export const PRODUCT_SEARCHED = 'Product Searched';
export const ADD_TO_BAG = 'Add_To_Bag';
export const SIGNED_UP_FOR_NEWSLETTER = 'Signed Up For News Letter';
export const STORE_VIEWED = 'Store Viewed';
export const VIEW_SHOPPING_BAG = 'View_Shopping_Bag';
export const CART_UPDATED = 'Cart Updated';
export const WISHLIST_UPDATED = 'Wishlist Updated';
export const COUPON_CODE_APPLIED = 'Coupon Code Applied';
export const COUPON_CODE_FAILED = 'Coupon Code Failed';
export const VIEW_STORE_CREDIT = 'View Store Credit';
export const REMOVED_FROM_CART = 'Removed From Cart';
export const ORDER_CANCELLED = 'Order Cancelled';
export const SHIPPING_DETAILS_UPDATED = 'Shipping Details Updated';
export const CHECKOUT_STARTED = 'Checkout Started';
export const CHECKOUT_COMPLETED = 'Checkout Completed';
export const QUICK_PRODUCT_VIEWED = 'Quick_Product_Viewed';
export const PRODUCT_VIEWED = 'Product Viewed';
export const ADD_TO_WISHLIST = 'Add_to_Wishlist';
export const REMOVED_FROM_WISHLIST = 'Remove_from_wishlist';
export const VIEW_LIST_BY = 'View List by';
export const USER_LOGGED_IN = 'User Logged In';
export const CHECK_DELIVERY = 'Check Delivery';
export const USER_SIGNED_UP = 'User Signed Up';
