/* eslint-disable @scandipwa/scandipwa-guidelines/always-both-mappings */
/* eslint-disable react/sort-comp */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-expressions */
/* eslint-disable new-cap */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

import './Zendesk.style';

/** @namespace Zendesk/Component/Zendesk/Component/ZendeskComponent */
export class ZendeskComponent extends PureComponent {
    static propTypes = {
        history: PropTypes.objectOf.isRequired,
        zendeskKey: PropTypes.string.isRequired
    };

    componentDidMount() {
        this.hideZendeskIcon();
    }

    componentDidUpdate() {
        this.hideZendeskIcon();
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            setting: {
                color: {
                    theme: '#000'
                },
                launcher: {
                    chatLabel: {
                        'en-US': 'Need Help'
                    }
                }

            }
        };
    }

    hideZendeskIcon = () => {
        const { history: { location } } = this.props;
        if (location.pathname === '/checkout/shipping' || location.pathname === '/checkout') {
            return ZendeskAPI('webWidget', 'hide');
        }
        if (location.pathname === '/cart') {
            return ZendeskAPI('webWidget', 'hide');
        }
        if (location.pathname === '/success') {
            return ZendeskAPI('webWidget', 'hide');
        }

        return ZendeskAPI('webWidget', 'show');
    };

    render() {
        const { setting } = this.state;
        const { zendeskKey } = this.props;

        if (!zendeskKey) {
            return null;
        }

        return (

            <div block="Zendesk">
                <Zendesk defer zendeskKey={ zendeskKey } id={ zendeskKey } { ...setting } onLoaded={ () => console.log('is loaded...') } />
            </div>
        );
    }
}
/** @namespace Zendesk/Component/Zendesk/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState,
    zendeskKey: state.ConfigReducer.zendesk_key
});

export default connect(mapStateToProps)(ZendeskComponent);
